<template>
  <div class="view-login auth mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <h1 class="text-center mb-5">
            <img src="../assets/logo-light.png" />
          </h1>

          <form class="card" @submit.prevent="submit">
            <div
              v-if="
                errors.email.length ||
                  errors.password.length ||
                  errors.non_field_errors.length
              "
              class="card-head-invalid-feedback bg-danger text-white"
            >
              <i class="icon-alert-circle mr-2" />
              {{ errorMsg }}
            </div>
            <div class="card-body p-5">
              <h5 class="text-center mb-4 text-dark">Faça seu login</h5>

              <form-group
                v-model="email"
                :is-login="true"
                id="email"
                type="email"
                label="E-MAIL"
                placeholder="Digite seu e-mail"
                :errors="errors.email"
                :show-error-message="false"
                @change="resetErrors"
              />

              <form-group
                v-model="password"
                :is-login="true"
                id="password"
                type="password"
                label="SENHA"
                placeholder="Digite a sua senha"
                :errors="errors.password"
                :show-error-message="false"
                @change="resetErrors"
              />

              <div class="text-center py-4">
                <hollow-button
                  :loading="loading"
                  title="Entrar"
                  type="submit"
                  :disabled="!isValidFields"
                />
              </div>

              <p class="text-center mb-0">
                <button
                  type="button"
                  @click="goToForgotPassword"
                  class="btn btn-sm text-primary"
                >
                  Esqueci minha senha
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HollowButton from "@/components/HollowButton";
import get from "lodash/get";

export default {
  name: "login",
  components: {
    HollowButton
  },
  data() {
    return {
      email: "",
      password: "",
      errors: {
        email: [],
        password: [],
        non_field_errors: []
      },
      loading: false,
      forgotDialog: false,
      isValidFields: false
    };
  },
  computed: {
    errorMsg() {
      return (
        get(this.errors, "email[0]") ||
        get(this.errors, "password[0]") ||
        get(this.errors, "non_field_errors[0]")
      );
    }
  },
  watch: {
    password() {
      this.validateFields();
    },
    email() {
      this.validateFields();
    }
  },
  methods: {
    validateFields() {
      const isValidEmail = !!this.email && /\S+@\S+\.\S+/.test(this.email);
      const isValidPassword = !!this.password && this.password.length > 0;
      this.isValidFields = isValidEmail && isValidPassword;
    },
    resetErrors() {
      this.errors = {
        email: [],
        password: [],
        non_field_errors: []
      };
    },
    goToForgotPassword() {
      this.$router.push("/password-recovery");
    },
    submit() {
      this.loading = true;
      this.resetErrors();

      this.$store
        .dispatch("user/login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.replace("/");
        })
        .catch(error => {
          const data = error.response.data;
          if (data.error === "invalid_grant") {
            this.errors.email = ["E-mail ou senha inválidos"];
            this.errors.password = ["E-mail ou senha inválidos"];
          } else {
            this.errors.non_field_errors = [data.error_description];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.view-login {
  width: 100%;

  .card-head-invalid-feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    font-size: 14px;
    border-radius: 16px 16px 0px 0px;
    position: absolute;
    top: -20px;
  }

  .btn-sm {
    font-size: 14px;
    font-weight: 900;
  }
}
</style>
